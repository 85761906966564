import React, { Suspense, lazy } from "react";
import "./App.less";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import Home from "./pages/Home";
import Events from "./pages/Events";
import AddEventGallery from "./pages/AddEventGallery";
import GalleryEvents from "./pages/GalleryEvents";
const IncomeExpense = lazy(() => import("./pages/IncomeExpense"));
const AccountStatements = lazy(() => import("./pages/AccountStatements"));
const IrregularStudents = lazy(() => import("./pages/IrregularStudents"));
const DirectorShare = lazy(() => import("./pages/DirectorShare"));
const Evaluation = lazy(() => import("./pages/Evaluation"));
const Evaluate = lazy(() => import("./pages/Evaluate"));
const Permissions = lazy(() => import("./pages/Permissions"));
const Roles = lazy(() => import("./pages/Roles"));
const RolePermissions = lazy(() => import("./pages/RolePermissions"));
const EvaluationHistory = lazy(() => import("./pages/EvaluationHistory"));
const EditEvaluation = lazy(() => import("./pages/EditEvaluation"));
const ManageClassroom = lazy(() => import("./pages/ManageClassroom"));
const Announcements = lazy(() => import("./pages/Announcements"));
const ReportCardPagePDF = lazy(() => import("./pages/PDF/ReportCardPagePDF"));
const RegPDFPage = lazy(() => import("./pages/PDF/RegPDFPage"));
const BookListPagePDF = lazy(() => import("./pages/PDF/BookListPagePDF"));
const PaymentPagePDF = lazy(() => import("./pages/PDF/PaymentPagePDF"));
const SalaryPagePDF = lazy(() => import("./pages/PDF/SalaryPagePDF"));
const StaffRegPDFPage = lazy(() => import("./pages/PDF/StaffRegPDFPage"));
const TCPagePDF = lazy(() => import("./pages/PDF/TCPagePDF"));
const PaymentDue = lazy(() => import("./pages/PaymentDue"));
const Docs = lazy(() => import("./pages/Docs"));
const AddDoc = lazy(() => import("./pages/AddDoc"));
const Transport = lazy(() => import("./pages/Transport"));
const Notice = lazy(() => import("./pages/Notice"));
const Term = lazy(() => import("./pages/Term"));
const Book = lazy(() => import("./pages/Book"));
const EditDoc = lazy(() => import("./pages/EditDoc"));
const Schedule = lazy(() => import("./pages/Schedule"));
const Subject = lazy(() => import("./pages/Subject"));
const Visitor = lazy(() => import("./pages/Visitor"));
const Attendance = lazy(() => import("./pages/Attendance"));
const AttendanceHistory = lazy(() => import("./pages/AttendanceHistory"));
const EditAttendance = lazy(() => import("./pages/EditAttendance"));
const PublishResult = lazy(() => import("./pages/PublishResult"));
const ClassRoutine = lazy(() => import("./pages/ClassRoutine"));
const AddStaff = lazy(() => import("./pages/AddStaff"));
const Staffs = lazy(() => import("./pages/Staffs"));
const StaffProfile = lazy(() => import("./pages/StaffProfile"));
const StaffSalary = lazy(() => import("./pages/StaffSalary"));
const Salaries = lazy(() => import("./pages/Salaries"));
const AuthRoute = lazy(() => import("./routes/AuthRoute"));
const Students = lazy(() => import("./pages/Students"));
const AddStudent = lazy(() => import("./pages/AddStudent"));
const StudentProfile = lazy(() => import("./pages/StudentProfile"));
const Login = lazy(() => import("./pages/Login"));
const Class = lazy(() => import("./pages/Class"));
const FileType = lazy(() => import("./pages/FileType"));
const PaymentType = lazy(() => import("./pages/PaymentType"));
const StudentPayment = lazy(() => import("./pages/StudentPayment"));
const Payments = lazy(() => import("./pages/Payments"));
const TransferredStudents = lazy(() => import("./pages/TransferredStudents"));
const ReportCardPage = lazy(() => import("./pages/ReportCardPage"));
const StaffAttendance = lazy(() => import("./pages/StaffAttendance"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const StudentAttendance = lazy(() => import("./pages/StudentAttendance"));
const TimeTable = lazy(() => import("./pages/TimeTable"));
const StudentResults = lazy(() => import("./pages/StudentResults"));
const StudentPaymentPage = lazy(() => import("./pages/StudentPaymentPage"));
const StaffSalaryPage = lazy(() => import("./pages/StaffSalaryPage"));
const BookListPage = lazy(() => import("./pages/BookListPage"));
const BookList = lazy(() => import("./pages/BookList"));
const TransferCertificatePage = lazy(() =>
  import("./pages/TransferCertificatePage")
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/"
                render={() => <AuthRoute Component={<Home />} />}
              />
              <Route
                exact
                path="/income-expense"
                render={() => <AuthRoute Component={<IncomeExpense />} />}
              />
              <Route
                exact
                path="/statements"
                render={() => <AuthRoute Component={<AccountStatements />} />}
              />
              <Route
                exact
                path="/director-share"
                render={() => <AuthRoute Component={<DirectorShare />} />}
              />
              <Route
                exact
                path="/students"
                render={() => <AuthRoute Component={<Students />} />}
              />
              <Route
                exact
                path="/staffs"
                render={() => <AuthRoute Component={<Staffs />} />}
              />
              <Route
                exact
                path="/addstudent"
                render={() => <AuthRoute Component={<AddStudent />} />}
              />
              <Route
                exact
                path="/addstaff"
                render={() => <AuthRoute Component={<AddStaff />} />}
              />
              <Route
                exact
                path="/student-profile/:id"
                render={() => <AuthRoute Component={<StudentProfile />} />}
              />
              <Route
                exact
                path="/staff-profile/:id"
                render={() => <AuthRoute Component={<StaffProfile />} />}
              />
              <Route
                exact
                path="/class"
                render={() => <AuthRoute Component={<Class />} />}
              />
              <Route
                exact
                path="/events"
                render={() => <AuthRoute Component={<Events />} />}
              />
              <Route
                exact
                path="/event-gallery"
                render={() => <AuthRoute Component={<GalleryEvents />} />}
              />
              <Route
                exact
                path="/add-event-gallery"
                render={() => <AuthRoute Component={<AddEventGallery />} />}
              />
              <Route
                exact
                path="/file-type"
                render={() => <AuthRoute Component={<FileType />} />}
              />
              <Route
                exact
                path="/payment-type"
                render={() => <AuthRoute Component={<PaymentType />} />}
              />
              <Route
                exact
                path="/payments"
                render={() => <AuthRoute Component={<Payments />} />}
              />
              <Route
                exact
                path="/salaries"
                render={() => <AuthRoute Component={<Salaries />} />}
              />
              <Route
                exact
                path="/registration-pdf/:id"
                render={() => <AuthRoute Component={<RegPDFPage />} />}
              />
              <Route
                exact
                path="/transfer-certificate-pdf/:id"
                render={() => <AuthRoute Component={<TCPagePDF />} />}
              />
              <Route
                exact
                path="/registration-staff-pdf/:id"
                render={() => <AuthRoute Component={<StaffRegPDFPage />} />}
              />
              <Route
                exact
                path="/book-list-pdf/:id"
                render={() => <AuthRoute Component={<BookListPagePDF />} />}
              />
              <Route
                exact
                path="/payment-pdf/:id/:pid"
                render={() => <AuthRoute Component={<PaymentPagePDF />} />}
              />
              <Route
                exact
                path="/salary-pdf/:id/:pid"
                render={() => <AuthRoute Component={<SalaryPagePDF />} />}
              />
              <Route
                exact
                path="/report-card-pdf/:id/:tid"
                render={() => <AuthRoute Component={<ReportCardPagePDF />} />}
              />
              <Route
                exact
                path="/student-payment/:id"
                render={() => <AuthRoute Component={<StudentPayment />} />}
              />
              <Route
                exact
                path="/staff-salary/:id"
                render={() => <AuthRoute Component={<StaffSalary />} />}
              />
              <Route
                exact
                path="/documents"
                render={() => <AuthRoute Component={<Docs />} />}
              />
              <Route
                exact
                path="/add-document"
                render={() => <AuthRoute Component={<AddDoc />} />}
              />
              <Route
                exact
                path="/edit-document/:id"
                render={() => <AuthRoute Component={<EditDoc />} />}
              />
              <Route
                exact
                path="/transport"
                render={() => <AuthRoute Component={<Transport />} />}
              />
              <Route
                exact
                path="/notice"
                render={() => <AuthRoute Component={<Notice />} />}
              />
              <Route
                exact
                path="/term"
                render={() => <AuthRoute Component={<Term />} />}
              />
              <Route
                exact
                path="/library"
                render={() => <AuthRoute Component={<Book />} />}
              />
              <Route
                exact
                path="/schedule"
                render={() => <AuthRoute Component={<Schedule />} />}
              />
              <Route
                exact
                path="/subject"
                render={() => <AuthRoute Component={<Subject />} />}
              />
              <Route
                exact
                path="/visitor"
                render={() => <AuthRoute Component={<Visitor />} />}
              />
              <Route
                exact
                path="/attendance/:id/:tid"
                render={() => <AuthRoute Component={<Attendance />} />}
              />
              <Route
                exact
                path="/attendance-history/:id/:tid"
                render={() => <AuthRoute Component={<AttendanceHistory />} />}
              />
              <Route
                exact
                path="/evaluation-history/:id/:tid"
                render={() => <AuthRoute Component={<EvaluationHistory />} />}
              />
              <Route
                exact
                path="/edit-evaluation/:id/:lid"
                render={() => <AuthRoute Component={<EditEvaluation />} />}
              />
              <Route
                exact
                path="/manage-classroom/:id"
                render={() => <AuthRoute Component={<ManageClassroom />} />}
              />
              <Route
                exact
                path="/attendance-edit/:id/:lid/:tid"
                render={() => <AuthRoute Component={<EditAttendance />} />}
              />
              <Route
                exact
                path="/result/:id/:lid"
                render={() => <AuthRoute Component={<PublishResult />} />}
              />
              <Route
                exact
                path="/evaluate/:id/:lid"
                render={() => <AuthRoute Component={<Evaluate />} />}
              />
              <Route
                exact
                path="/class-routine"
                render={() => <AuthRoute Component={<ClassRoutine />} />}
              />
              <Route
                exact
                path="/student-attendance"
                render={() => <AuthRoute Component={<StudentAttendance />} />}
              />
              <Route
                exact
                path="/time-table"
                render={() => <AuthRoute Component={<TimeTable />} />}
              />
              <Route
                exact
                path="/class-announcements"
                render={() => <AuthRoute Component={<Announcements />} />}
              />
              <Route
                exact
                path="/results"
                render={() => <AuthRoute Component={<StudentResults />} />}
              />
              <Route
                exact
                path="/evaluation"
                render={() => <AuthRoute Component={<Evaluation />} />}
              />
              <Route
                exact
                path="/student-tuition-fee"
                render={() => <AuthRoute Component={<StudentPaymentPage />} />}
              />
              <Route
                exact
                path="/student-tuition-due"
                render={() => <AuthRoute Component={<PaymentDue />} />}
              />
              <Route
                exact
                path="/staff-salary-page"
                render={() => <AuthRoute Component={<StaffSalaryPage />} />}
              />
              <Route
                exact
                path="/book-list-page"
                render={() => <AuthRoute Component={<BookListPage />} />}
              />
              <Route
                exact
                path="/book-list/:id"
                render={() => <AuthRoute Component={<BookList />} />}
              />
              <Route
                exact
                path="/transfer-certificate"
                render={() => (
                  <AuthRoute Component={<TransferCertificatePage />} />
                )}
              />
              <Route
                exact
                path="/transferred-students"
                render={() => <AuthRoute Component={<TransferredStudents />} />}
              />
              <Route
                exact
                path="/irregular-students"
                render={() => <AuthRoute Component={<IrregularStudents />} />}
              />
              <Route
                exact
                path="/report-card"
                render={() => <AuthRoute Component={<ReportCardPage />} />}
              />

              <Route
                exact
                path="/staff-attendance"
                render={() => <AuthRoute Component={<StaffAttendance />} />}
              />
              <Route
                exact
                path="/roles"
                render={() => <AuthRoute Component={<Roles />} />}
              />
              <Route
                exact
                path="/role-permissions/:id"
                render={() => <AuthRoute Component={<RolePermissions />} />}
              />
              <Route
                exact
                path="/permissions"
                render={() => <AuthRoute Component={<Permissions />} />}
              />
              <Route component={ErrorPage} />
            </Switch>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
