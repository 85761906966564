import axios from "axios";
import Url from "../utils/Urls";

export const getStaffStats = (setData) => {
  axios
    .get(Url + "/staff-stats")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getAvailableStudent = (setData) => {
  axios
    .get(Url + "/total-student-present")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getAvailableStuff = (setData) => {
  axios
    .get(Url + "/total-staff-available")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getStudentsStats = (setData) => {
  axios
    .get(Url + "/student-stats")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getStudentsTuitions = (date, setData) => {
  axios
    .post(Url + "/monthly-student-tuition", date)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCostByRange = (date, setData) => {
  axios
    .post(Url + "/monthly-salary-cost", date)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
