import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/charts";
import { getAllClass } from "../../api/Class";
import color from "../../utils/Theme";

export default function AttendanceBar() {
  const [data, setData] = useState(null);
  useEffect(() => {
    getAllClass(setData);
  }, []);
  var config = {
    data: data && data.classes ? data.classes : [],
    xField: "name",
    yField: "students_count",
    seriesField: "",
    color: color.color,
    legend: false,
  };
  return (
    <div>
      <Column {...config} style={{ height: "200px" }} />
    </div>
  );
}
