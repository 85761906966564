import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";

export const getAllFileTypes = (setData) => {
  axios
    .get(Url + "/staff/file-type")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getAllEvents = (setData) => {
  axios
    .get(Url + "/admin/event-types")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getGalleryEvents = (filter , setData) => {
  axios
    .get(Url + "/admin/events" + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const deleteGalleryEvents = (id , afterPerform) => {
  axios
    .delete(Url + `/admin/events/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(null);
    });
};

export const addNewFileType = (data, openNotificationWithIcon, setData) => {
  axios
    .post(Url + "/staff/file-type", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "File Type added Successfully"
      );
      getAllFileTypes(setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};


export const addNewEvent = (data, openNotificationWithIcon, setData) => {
  axios
    .post(Url + "/admin/event-types", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "File Type added Successfully"
      );
      getAllEvents(setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateFileType = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset
) => {
  axios
    .put(Url + `/staff/file-type/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "File Type updated Successfully"
      );
      getAllFileTypes(setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateEvent = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset
) => {
  axios
    .put(Url + `/admin/event-types/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "updated Successfully"
      );
      getAllFileTypes(setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};




export const addNewFile = (
  data,
  openNotificationWithIcon,
  getStudentAfterUpadte,
  reset
) => {
  axios
    .post(Url + "/staff/student-file", data)
    .then((res) => {
      openNotificationWithIcon("success", "Success", "File added Successfully");
      getStudentAfterUpadte(reset);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const addNewFileStaff = (
  data,
  openNotificationWithIcon,
  getStaffAfterUpadte,
  reset
) => {
  axios
    .post(Url + "/admin/staff-file", data)
    .then((res) => {
      openNotificationWithIcon("success", "Success", "File added Successfully");
      getStaffAfterUpadte(reset);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const addNewDocument = (data, openNotificationWithIcon, reset) => {
  axios
    .post(Url + "/staff/docs", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Document added Successfully"
      );
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const addNewGalleryItem = (data, openNotificationWithIcon, reset) => {
  axios
    .post(Url + "/admin/events", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Picture added Successfully"
      );
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateOldDocument = (
  id,
  data,
  openNotificationWithIcon,
  reset
) => {
  axios
    .post(Url + `/staff/docs/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Document updated Successfully"
      );
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const getAllDocs = (setData) => {
  axios
    .get(Url + "/staff/docs")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getDocById = (id, setData) => {
  axios
    .get(Url + `/staff/docs/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
