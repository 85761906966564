import React, { useState, useEffect } from "react";
import { DatePicker, Card, Typography } from "antd";
import { getStudentsTuitions } from "../../api/Dashboard";
import { EuroOutlined } from "@ant-design/icons";
import moment from "moment";
import color from "../../utils/Theme";
const { Title } = Typography;

export default function IncomeRange() {
  const [income, setIncome] = useState(null);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let dateFormated = new Date(date);
    getStudentsTuitions(
      {
        month: dateFormated.getMonth() + 1,
        year: dateFormated.getFullYear(),
      },
      setIncome
    );
  }, [date]);

  let onChange = (dateNow) => {
    setDate(dateNow);
  };

  return (
    <Card
      style={{ height: "100%" }}
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            height={128}
            width={140}
            src={require("../../assets/vectors/income.svg")}
            alt=""
            style={{ marginBottom: "0.5rem" }}
          />
          <h3>
            Fees Collected in {moment(new Date(date)).format("MMMM YYYY")}
          </h3>
          <DatePicker
            picker="month"
            style={{ width: "100%" }}
            format="YYYY/MM"
            defaultValue={moment(new Date(), "YYYY/MM")}
            onChange={onChange}
          />
        </div>
      }
    >
      <Title level={4} style={{ marginBottom: "0", color: color.highlight }}>
        <EuroOutlined />
        {income ? "  " + income.monthly_tuition : "  0"}
      </Title>
    </Card>
  );
}
