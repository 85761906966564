import { DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  deleteGalleryEvents,
  getAllEvents,
  getGalleryEvents,
} from "../api/File";

export default function GalleryEvents() {
  const [data, setData] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [filter, setFilter] = useState(2);
  const [reload, setReload] = useState(2);

  useEffect(() => {
    getAllEvents(setEventType);
  }, []);
  useEffect(() => {
    setData(null)
    getGalleryEvents(`?event_type_id=${filter}`, setData);
  }, [filter, reload]);

  const getNameDes = (title , type) =>{
      if(type === 'name'){
        if(title.includes('#')){
          let split = title.split('#')
          let name = split[0]
          return name
        }
      }else if(type === 'des'){
        if(title.includes('#')){
          let split = title.split('#')
          let des = split.length > 1 ? split[1] : null
          return des
        }
      }
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Select
          style={{ width: "200px" }}
          placeholder="Please select"
          onChange={(e) => setFilter(e)}
        >
          {eventType && eventType.length > 0
            ? eventType.map((p, k) => (
                <Select.Option value={p.id} key={p.id}>
                  {p.name + " : " + p.date}
                </Select.Option>
              ))
            : ""}
        </Select>
      </div>
      <div style={{ marginTop: "1rem" }}>
        {data ? (
         
            <div>
            {Object.entries(data).map((el) => (
              <Row gutter={[12,12]}>
                {el[1].map((elm) => (
                  <Col md={{ span: 4 }} key={el[0]} style={{height:'100%'}}>
                  <div style={{ background: "#fff" , width:'100%', height:'100%' }}>
                    <img
                      alt="example"
                      style={{
                        minHeight: "160px",
                        objectFit: "cover",
                        maxWidth: "100%",
                      }}
                      src={elm.file}
                    />
                    {elm.title !== 'title' &&  <div style={{ padding: "1rem" , height: 110 }}>
                      <span>{getNameDes(elm.title , 'name')}</span>
                      <p>{getNameDes(elm.title , 'des') ? getNameDes(elm.title , 'des') : ''}</p>
                    </div>}
                    <DeleteOutlined
                      style={{
                        position: "absolute",
                        right: 12,
                        top: 12,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        deleteGalleryEvents(elm.id, () => {
                          setReload(Math.random());
                        });
                      }}
                    />
                  </div>
              </Col>
                ))}

                          </Row>
            ))}

            </div>
        ) : null}
      </div>
    </div>
  );
}
