import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/charts";
import { getAvailableStudent } from "../../api/Dashboard";
import color from "../../utils/Theme";
export default function AttendancePie({ total }) {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    getAvailableStudent(setStats);
  }, []);

  var data = [
    {
      type: "Present",
      value: stats ? stats.present : total.current_student,
    },
    {
      type: "Absent",
      value: stats ? total.current_student - stats.present : 0,
    },
  ];
  var config = {
    appendPadding: 0,
    data: data,
    angleField: "value",
    colorField: "type",
    color: [color.color, "#2f2e41"],
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: function content(_ref) {
        var percent = _ref.value;
        return "".concat(percent);
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        formatter: function formatter() {
          return total.current_student;
        },
      },
    },
  };
  return <Pie {...config} style={{ height: "200px" }} />;
}
