import React from "react";
import { Bar } from "@ant-design/charts";
import theme from "../../utils/Theme";
export default function RatioBar(props) {
  var data = [
    {
      type: "Student",
      value: parseInt(props.student),
    },
    {
      type: "Teacher",
      value: parseInt(props.teacher),
    },
    {
      type: "Staff",
      value: parseInt(props.staff) - parseInt(props.teacher),
    },
  ];
  var config = {
    data: data,
    xField: "value",
    yField: "type",
    seriesField: "type",
    color: function color(_ref) {
      var type = _ref.type;
      if (type === "Teacher") {
        return "#2f2e41";
      } else if (type === "Staff") {
        return "#222222";
      } else {
        return theme.color;
      }
    },
    legend: { position: "top-left" },
  };
  return <Bar {...config} style={{ height: "220px" }} />;
}
