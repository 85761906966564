import React, { Suspense, lazy, useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import AttendancePie from "../components/Charts/AttendancePie";
import AttendanceBar from "../components/Charts/AttendanceBar";
import { getStaffStats, getStudentsStats } from "../api/Dashboard";
import Spinner from "../components/Spinner";
import StaffAvailablePie from "../components/Charts/StaffAvailablePie";
import IncomeRange from "../components/Charts/IncomeRange";
import CostRange from "../components/Charts/CostRange";
import RatioBar from "../components/Charts/RatioBar";
import RatioJade from "../components/Charts/RatioJade";
import { PieChartOutlined } from "@ant-design/icons";
const UserCount = lazy(() => import("../components/UserCount"));
const { Title } = Typography;

export default function Home() {
  const [user, setuser] = useState(null);
  const [staff, setstaff] = useState(null);
  useEffect(() => {
    getStaffStats(setstaff);
    getStudentsStats(setuser);
  }, []);
  return (
    <div>
      <Suspense fallback="">
        {user && staff ? (
          <div>
            <UserCount user={user} staff={staff} />
            <Row gutter={[16, 16]} style={{ marginTop: "0.5rem" }}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <IncomeRange />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CostRange />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Card
                  title="Student Attendance - Today"
                  style={{ height: "100%" }}
                >
                  <AttendancePie total={user} />
                </Card>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Card
                  title="Staff Attendance - Today"
                  style={{ height: "100%" }}
                >
                  <StaffAvailablePie total={staff} />
                </Card>
              </Col>
              <Col xs={{ span: 24 }}>
                <Card title="Students Status" style={{ height: "100%" }}>
                  <AttendanceBar />
                </Card>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                <Card>
                  <RatioBar
                    teacher={
                      staff && staff.teacher_count ? staff.teacher_count : 0
                    }
                    staff={staff && staff.total_staff ? staff.total_staff : 0}
                    student={
                      user && user.current_student ? user.current_student : 0
                    }
                  />
                </Card>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Card
                  size="small"
                  title={
                    <RatioJade
                      teacher={
                        staff && staff.teacher_count ? staff.teacher_count : 0
                      }
                      student={
                        user && user.current_student ? user.current_student : 0
                      }
                    />
                  }
                  style={{ height: "100%" }}
                  bordered={true}
                >
                  <Title
                    level={5}
                    style={{ marginBottom: "0px", color: "#018164" }}
                  >
                    <PieChartOutlined
                      style={{
                        fontSize: "18px",
                        marginRight: "0.5rem",
                      }}
                    />
                    Teacher Status
                    {staff &&
                    staff.teacher_count &&
                    user &&
                    user.current_student
                      ? user.current_student / staff.teacher_count <= 20
                        ? " - Sufficient"
                        : " - Insufficient"
                      : ""}
                  </Title>
                </Card>
              </Col>
              {/* <Col xs={{ span: 24 }}>
                <CalenderData />
              </Col> */}
            </Row>
          </div>
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </Suspense>
    </div>
  );
}
