import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Modal,
  DatePicker,
} from "antd";
import { addNewEvent, getAllEvents, updateEvent,  } from "../api/File";
import Spinner from "../components/Spinner";
import moment from "moment"
import Text from "antd/lib/typography/Text";
const { Column } = Table;

export default function Events() {
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const serverDateFormat = "YYYY-MM-DD";
  const [id, setId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllEvents(setData);
  }, []);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const modalCancel = () => {
    setId(null);
    setModal(false);
    form.setFieldsValue({});
  };

  const onFailedSubmit = (error) => {
    openNotificationWithIcon(
      "error",
      "Failed to submit",
      "You have missed some important information. Please fill those up to complete action."
    );
  };

  const onSubmitForm = (values) => {
    let data = {
        name: values.name,
      date: moment(values.date).format(serverDateFormat),
      description: values.description,
    };
    addNewEvent(data, openNotificationWithIcon, setData);
  };

  const updateForm = (values) => {
    let formdata = {
        name: values.name,
      date:  moment(values.date).format(serverDateFormat),
      description: values.description,
    };
    updateEvent(
      id,
      formdata,
      openNotificationWithIcon,
      setData,
      modalCancel
    );
  };

  return (
    <div>
      <Form
        name="basic"
        layout="vertical"
        onFinish={onSubmitForm}
        onFinishFailed={onFailedSubmit}
      >
        <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
        <Col xs={{ span: 24 }} md={{ span: 7 }}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please insput name!",
                },
              ]}
            >
               <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 7 }}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
               <DatePicker style={{ width: "100%" }} format={dateFormat} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 7 }}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input file type description!",
                },
              ]}
            >
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Add Event
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {data ? (
        <Table dataSource={data} rowKey={(record) => record.id}>
            <Column title="Name" dataIndex="name" key="name" />
            <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Text>{record.date}</Text>
            )}
          />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Button
                  type="primary"
                  onClick={() => {
                    setId(record.id);
                    setModal(true);
                    form.setFieldsValue({
                      name: record.name,
                      date: moment(new Date(record.date), dateFormat),
                      description: record.description,
                    });
                  }}
                >
                  Edit
                </Button>
              </Space>
            )}
          />
        </Table>
      ) : (
        <Spinner />
      )}
      <Modal
        title="Update Event"
        visible={modal}
        onCancel={modalCancel}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={updateForm}
          onFinishFailed={onFailedSubmit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="date"
            label="Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
             <DatePicker style={{ width: "100%" }} format={dateFormat} />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please input file type description!",
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>

          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
            <Button onClick={modalCancel} style={{ marginLeft: "16px" }}>
              Return
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
