import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Typography,
  Upload,
  Card,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { addNewDocument, addNewGalleryItem, getAllEvents, getAllFileTypes } from "../api/File";
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
export default function AddEventGallery() {
  const [fileType, setFileType] = useState(null);
  const [docfileList, setDocFileList] = useState([]);
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [docType, setDocType] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllEvents(setFileType);
  }, []);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  const onFailedSubmit = (error) => {
    openNotificationWithIcon(
      "error",
      "Failed to submit",
      "You have missed some important information. Please fill those up to complete action."
    );
  };

  const docreset = () => {
    setUploadingDoc(false);
    setDocFileList([]);
    form.resetFields();
  };

  const onSubmitFile = (values) => {
    if (docfileList.length > 0) {
      setUploadingDoc(true);
      let formdata = new FormData();
      formdata.append("event_type_id", values.event_type_id);
      formdata.append("is_published", 1);
      formdata.append("files[][file]", docfileList[docfileList.length - 1]);
      formdata.append("files[][title]", values.title ? values.title : 'title');
      addNewGalleryItem(formdata, openNotificationWithIcon, docreset);
    } else {
      openNotificationWithIcon(
        "error",
        "Form Error",
        "You have missed some important student information. Please fill those up to complete student registration."
      );
    }
  };
  function beforeUploadFile(file) {
    setDocFileList((state) => [...state, file]);
    return false;
  }

  return (
    <Card>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={onSubmitFile}
        onFinishFailed={onFailedSubmit}
      >
        <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ padding: "1rem" }}>
            <img
              width="120px"
              style={{ marginBottom: "0.5rem" }}
              src={require("../assets/vectors/student.svg")}
              alt=""
            />
            <Title level={3}>Add Document</Title>
            <Text type="secondary">
              Admin can upload diffrent types of documents for future use.
              Please add notes for identifing a file and upload only pdf/doc
              type files upto 2 megabytes.
            </Text>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Row gutter={[16, 0]}>
              <Col xs={{span: 24}}>
              <Form.Item
                name="title"
                style={{  }}
              >
                <Input placeholder="Title" />
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Event Type"
                  name="event_type_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select event type",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onChange={() => setDocType(true)}
                  >
                    {fileType &&
                    fileType.length > 0
                      ? fileType.map((p, k) => (
                          <Option value={p.id} key={p.id}>
                            {p.name + ' : ' + p.date}
                          </Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
              
              <Form.Item
                name="docfile"
                style={{ marginLeft: "8px", marginBottom: "0.5rem" }}
              >
                <Upload
                  beforeUpload={beforeUploadFile}
                  multiple={false}
                  showUploadList={false}
                  fileList={docfileList}
                >
                  <Button icon={<UploadOutlined />}>Browse File</Button>
                </Upload>
              </Form.Item>
              <Form.Item style={{ marginLeft: "16px", marginBottom: "0.5rem" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={docfileList.length === 0 || !docType}
                  loading={uploadingDoc}
                >
                  {uploadingDoc ? "Uploading" : "Start Upload"}
                </Button>
              </Form.Item>
              <Col xs={{ span: 24 }}>
                {docfileList.length > 0 && (
                  <Text style={{ color: "red" }}>
                    Selected File: {docfileList[docfileList.length - 1].name}
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
